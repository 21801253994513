module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js","pages":"/opt/build/repo/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownLayout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":510,"maxHeight":510,"linkImagesToOriginal":false,"withWebp":true,"quality":75}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Delicious Recipes","short_name":"Recipes","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"standalone","icon":"/opt/build/repo/src/images/favicon.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a1f8f2fc76edd9e347570fe0f644e125"},
    },{
      plugin: require('../node_modules/@marinda/gatsby-theme-recipes/gatsby-browser.js'),
      options: {"plugins":[],"iconPath":"src/images/favicon.png","contentPath":"recipes"},
    }]
